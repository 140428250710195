/* @contract search-results
{
  li-highlight {
      box-shadow: BoxShadow
  }
  container-mobile-padding: Padding
  container-desktop-padding: Padding
  listing-card-min-height: Height
  listing-card-skeleton-height: Height

}
*/

.srpCardContainer {
  list-style-type: none;
  border-radius: var(--border-radius-5x);

  &:hover .carouselControl {
    opacity: 1;
  }

  &.activeFromMap {
    transition: box-shadow 0.3s ease-in-out;
    border-radius: var(--border-radius-5x);
    box-shadow: var(--search-results-li-highlight-box-shadow);
  }
}

.srpContainer {
  padding: var(--search-results-container-mobile-padding);
  gap: var(--space-4x);
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  z-index: 0;

  @media (--screen-md) {
    padding: var(--search-results-container-desktop-padding);
  }

  @media (--screen-xl) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    --listing-card-min-height: var(--search-results-listing-card-min-height);
    --listing-card-skeleton-height: var(
      --search-results-listing-card-skeleton-height
    );
  }
}

.srpNoResults {
  --heading-padding: var(--space-3x) 0;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  padding: 50px 0;

  @media (--screen-lg) {
    padding: var(--space-20x) 0;
  }
}

.srpNoResultsText {
  white-space: pre-line;
  width: 275px;
  color: var(--color-gray-500);
  text-align: center;

  @media (--screen-lg) {
    width: auto;
    text-align: center;
  }
}

.srpNoResultsImage {
  display: var(--no-results-image-display);
}
